import React, { Component } from 'react';
import { geolocated } from "react-geolocated";
import { compose } from 'recompose';
import { Row, Col, Alert, Icon } from 'antd'
import axios from 'axios'
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { withAuthorization, withEmailVerification } from "../../containers/Session";
import { withFirebase } from "../../containers/Firebase";
import logo from "../../assets/logo.png";
import ReportedPerson from './components/reportedPerson';
import Identification from './components/identification';
import Result from './components/result';
import Questions from '../Questions';
import { insertReport } from './functions';
import * as ROUTES from '../../constants/routes';
import { createBrowserHistory } from "history";
import Questionario from '../Questionario';
import { getSessionUser } from '../../containers/Session/functions';

const INITIAL_STATE = {
    isScanning: false,
    isInsertCpf: false,
    isFinished: false,
    insertFeedback: null,
    // Quando clica em responder quetionário, esta variável representa o layout do questionario
    responderQuestionario: null,
    questionarioRespondido: false,
    alertasRespostaQuestionario: [],
    data: {
        badge: null,
        cpf: null,
        //cpf: '1',
        name: null,
        unidade: {
            id: null,
            nome: null
        },
        sender: {
            name: null,
            email: null,
            id: null,
        },
        createdAt: null,
        location: {
            lat: 0,
            lng: 0
        },
        questions: {}
    },
    ultimoAvaliado: {}, // copia do ultimo "data"
    debugMessage: null,
}

const feedbackIcon = [
    <Icon type="warning" />,
    <Icon type="warning" />,
    <Icon type="check-circle" />,
]
const feedbackState = [
    "error",
    "warning",
    "success",
]
const feedbackColor = [
    "#e74c3c",
    "#fac814",
    "#0A1B3F",
]

class Dashboard extends Component {
    constructor() {
        super()
        this.state = {
            ...INITIAL_STATE,
            sender: this.getSessionUser()
        }
    }

    updatePosition = null;

    componentDidMount() {
        if (!localStorage.getItem("authUser")) {
            this.props.history.push(ROUTES.SIGN_IN);
            return;
        }

        window.setTimeout(this.handlePosition, 1000);
        this.updatePosition = window.setInterval(this.handlePosition, 5000);
        const user = JSON.parse(localStorage.getItem('authUser'));
        this.setState({
            data: {
                ...this.state.data,
                sender: this.getSessionUser()
            }
        });
    }

    getAxiosInstance() {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                "Authorization": "Bearer " + (localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser')).jwtToken),
            },

        })
    }

    getSessionUser() {
        const user = getSessionUser();
        return user;
    }

    handleBeginScan = () => {
        this.setState({
            isScanning: true
        })

        // this.handleScan("07072034900");
    }

    handleCpfModal = show => {
        this.setState({
            ...this.state,
            isInsertCpf: show
        })
    }

    handleAutoAvaliacao = () => {
        const user = this.props.firebase.auth.user;
        this.getAxiosInstance().get(`funcionario/${user.cpf}`)
            .then(res => this.handleCpf(
                res.data[0],
                res.data[0].cpf,
                res.data[0].nome,
                res.data[0].contato ? res.data[0].contato.celular : user.phone,
                res.data[0].unidade,
                res.data[0].unidadeId,
                ))
            .catch(error => {
                console.warn("Não foi possível localizar dados de funcionário com seu cadastro.");
                console.warn("Seguindo com dados do usuário.");
                console.warn(error.message);
                const func = {
                    cpf: user.cpf,
                    nome: user.name,
                    contato: {
                        celular: user.phone
                    },
                    unidade: null,
                    endereco: {}
                };
                this.handleCpf(func, user.cpf, user.name, user.phone, null, null);
            });
    }

    handleLogout = () => {
        this.props.firebase.doSignOut();
    }

    handleCpf = (funcionario, cpf, name, celular, unidade, unidadeId) => {
        this.setState({
            data: {
                ...this.state.data,
                cpf: cpf.replace(/[^0-9]/g,''),
                name: name,
                celular: celular,
                unidade: {
                    id: unidadeId,
                    nome: unidade
                },
                funcionario: funcionario != null ? funcionario : {
                    cpf: cpf.replace(/[^0-9]/g,''),
                    nome: name,
                    contato: {
                        celular: celular
                    },
                    endereco: {}
                },
                novoFuncionario: funcionario == null
            },
            isInsertCpf: false
        });
    }

    handleScan = (qrcodeRead) => {
        if (qrcodeRead) {
            const cpf = qrcodeRead.trim();
            this.setState({
                debugMessage: qrcodeRead
            });

            if (cpf && cpf.trim() && cpf.length === 11) {
                this.getAxiosInstance().get(`funcionario/${cpf}`)
                    .then(res => {
                        const func = res.data && res.data.length > 0 ? res.data[0] : null;
                        if (func) {
                            this.handleCpf(
                                func,
                                func.cpf,
                                func.nome,
                                func.contato ? func.contato.celular : null,
                                func.unidade,
                                func.unidadeId);
                        } else {
                            this.setState({
                                debugMessage: `Funcionário com CPF ${cpf} não localizado.`,
                                isScanning: false
                            });
                            this.setState({isScanning: true}); // True false para reinicializar camera - Estou utilizando rotina de uma caputura de QR Code para camera ser finalizada após a leitura
                        }
                    })
                    .catch(error => {
                        console.warn("Não foi possível localizar dados de funcionário com seu cadastro.");
                        Alert.error(`Erro ao consultar funcionário com CPF ${cpf}. Mensagem: ${error.message}`);
                        this.setState({
                            debugMessage: `Erro ao consultar CPF: ${cpf}. Message: ${error.message}.`,
                            isScanning: false
                        });
                        this.setState({isScanning: true}); // True false para reinicializar camera - Estou utilizando rotina de uma caputura de QR Code para camera ser finalizada após a leitura
                    });
            }
        } else {
            this.setState({
                debugMessage: "Posicione a câmera no QR Code"
            });
        }
    }

    handlePosition = () => {
        if (this.props.isGeolocationEnabled && this.props.coords) {
            this.setState({
                data: {
                    ...this.state.data,
                    location: {
                        lat: this.props.coords.latitude,
                        lng: this.props.coords.longitude
                    }
                }
            })
            return true;
        } else {
            return false;
        }
    }

    handleQuestion = async questions => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                questions: questions
            },
            isFinished: true
        });
        await this.handleInsert();
    }

    handleInsert = async () => {
        this.setState({
            data: {
                ...this.state.data,
                createdAt: moment().format(),
            }
        })

        const user = this.getSessionUser();
        const sendData = {
            ...this.state.data,
            ...this.state.data.questions,
            cpf: this.state.data.cpf ? this.state.data.cpf.replace(/[^0-9]/gi, '') : null,
            sender: user
        };
        delete sendData.questions;

        let insertReturn = await insertReport(sendData);

        this.setState({
            insertFeedback: insertReturn.data
        })

        return insertReturn;
    }

    handleRestartClick = () => {
        this.setState({
            ...INITIAL_STATE,
            sender: this.getSessionUser()
        });
    }

    handleNovoDiagnosticoClick = (scanearCracha) => {
        if (scanearCracha) {
            this.setState({
                ...INITIAL_STATE,
                sender: this.getSessionUser(),
                isScanning: true
            });
        } else {
            this.setState({
                ...INITIAL_STATE,
                sender: this.getSessionUser(),
                isInsertCpf: true
            });
        }
    }

    handleResponderQuestionarioClick = (questionario) => {
        this.setState({
            responderQuestionario: questionario,
            questionarioRespondido: false
        });
    }

    handleQuestionarioConcluido = (alertas) => {
        this.setState({
            responderQuestionario: null,
            questionarioRespondido: true,
            alertasRespostaQuestionario: alertas
        });
    }

    handleQuestionarioVoltarTelaInicialClick = () => {
        this.setState({
            responderQuestionario: null,
            questionarioRespondido: false
        });
    }

    render() {
        return (
            <div>
                <div className="wrapper">
                    <div style={{ height: "100vh" }}>
                        <Row>
                            <Col className={"text-center"} md={24}>
                                <img width={"200px"} className="mb-20" src={logo} alt="logo" />
                            </Col>
                        </Row>

                        {
                            // Alerta Covid
                            this.state.responderQuestionario == null && this.state.isFinished && this.state.insertFeedback &&
                            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                <Col className={'mb-30'}>
                                    <h2 className={"text-center"}>Obrigado</h2>
                                    <p className={"text-center"}>
                                        Aqui vai uma mensagem para o colaborador(a) avaliado:
                                    </p>
                                    <p className={"text-center"}>
                                        {this.state.insertFeedback.warning.baseMsg}
                                    </p>
                                    {
                                        <Alert
                                            style={{
                                                borderColor: feedbackColor[this.state.insertFeedback.warning.hasteKey]
                                            }}
                                            className={'custom-alert'}
                                            message={<strong>Atenção</strong>}
                                            description={this.state.insertFeedback.warning.warning}
                                            type={feedbackState[this.state.insertFeedback.warning.hasteKey]}
                                            showIcon
                                            icon={feedbackIcon[this.state.insertFeedback.warning.hasteKey]}
                                        />
                                    }
                                </Col>
                            </Row>
                        }

                        {
                            // Alerta questionário de inicio de viagem (E demais caso haver posteriormente)
                            this.state.responderQuestionario == null &&
                            this.state.alertasRespostaQuestionario.length > 0 &&
                            this.state.alertasRespostaQuestionario.map((alerta) =>
                                <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    <Col className={'mb-30'}>
                                        <Alert
                                            style={{borderColor: "#e74c3c"}}
                                            className="custom-alert"
                                            message={<strong>{alerta.titulo}</strong>}
                                            description={alerta.mensagem}
                                            type={alerta.tipo}
                                            showIcon
                                            icon={<Icon type={alerta.icone} />}
                                        />
                                    </Col>
                                </Row>
                            )
                        }

                        {
                            this.state.responderQuestionario != null
                                ?
                                <Questionario
                                    questionario={this.state.responderQuestionario}
                                    session={this.state.data}
                                    onQuestionarioConcluido={(alertas) => this.handleQuestionarioConcluido(alertas)}
                                    onVoltarTelaInicialClick={() => this.handleQuestionarioVoltarTelaInicialClick()} />
                                :
                                this.state.isFinished
                                    ?
                                    <Result data={this.state.data}
                                            feedback={this.state.insertFeedback}
                                            questionarioRespondido={this.state.questionarioRespondido}
                                            onRestartClick={this.handleRestartClick}
                                            onNovoDiagnosticoClick={this.handleNovoDiagnosticoClick}
                                            onResponderQuestionarioClick={this.handleResponderQuestionarioClick} />
                                    :
                                    this.state.data.badge || this.state.data.cpf
                                        ?
                                        <Questions state={this.state} handleQuestion={this.handleQuestion} />
                                        :
                                        !this.state.isScanning && !this.state.isInsertCpf
                                            ?
                                            <Identification
                                                handleBeginScan={this.handleBeginScan}
                                                handleCpfModal={this.handleCpfModal}
                                                handleAutoAvaliacao={this.handleAutoAvaliacao}
                                                handleLogout={this.handleLogout} />
                                            :
                                            <ReportedPerson
                                                isScanning={this.state.isScanning}
                                                isInsertCpf={this.state.isInsertCpf}
                                                handleScan={this.handleScan}
                                                handleCpfModal={this.handleCpfModal}
                                                handleCpf={this.handleCpf}
                                                debugMessage={this.state.debugMessage}
                                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

// just check if its not null
const condition = authUser => {
    if (!authUser) {
        createBrowserHistory().push(ROUTES.SIGN_IN);
        window.location.reload();
    }
    return !!authUser;
}
export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withRouter,
    withFirebase,
    geolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: 5000
    })
)(Dashboard);