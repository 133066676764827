export const LANDING = '/landing';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_CHANGE = '/pw-change';

export const DASHBOARD = '/diagnostico';
