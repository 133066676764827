import React from 'react';
import { Button, Col } from 'antd'

const SubmitOrFinishAdvance = ({ disabled, step, setStep, isSubmit }) => (
    <Col xs={24}>
        <Button
            block
            className="btn-brand mb-30"
            disabled={disabled}
            htmlType="submit">
            Finalizar avaliação
        </Button>
        <Button
            onClick={() => !isSubmit && setStep(step + 1)}
            block
            className="btn-brand mb-30"
            disabled={disabled}
            htmlType={isSubmit ? "submit" : 'button'}>
            Registrar sintomas
        </Button>
    </Col>
);

export default SubmitOrFinishAdvance;