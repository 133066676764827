import React from 'react';
import { Row, Col, Typography, Radio, Input, Button } from 'antd'

const {TextArea} = Input;

const RespostaString = ({resposta, onAvancarClick}) => {
    let text = "";
    return (
        <Row style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            <Col className={"mb-30 text-center"} xs={24} md={12}>
                <TextArea
                    onChange={(event) => text = event.target.value}
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    // name="obs"
                />
            </Col>
            <Col xs={24}>
                <Button
                    onClick={(event) => onAvancarClick(text)}
                    block
                    className="btn-brand mb-30"
                    htmlType='button'>
                    Avançar
                </Button>
            </Col>
        </Row>
    )
}

export default RespostaString;