import React, { Component } from 'react';
import Alert from 'react-s-alert';
import { Row, Col, Modal, Input, Skeleton, Table, Typography, Radio, Button, Icon } from 'antd'
import device from 'current-device';
import axios from 'axios';
import RespostaBoolean from './respostaBoolean';
import RespostaString from './respostaString';
import { getSessionUser } from './../../containers/Session/functions';
const { Text } = Typography;
const _ = require("lodash");

const INITIAL_STATE = {
    perguntaIndex: 0,
    pergunta: null,
    resposta: null,
    perguntaAnteriorIndex: 0,
    ultimaPergunta: false,
    loading: false
};

class Questionario extends Component {
    questionario = null;
    session = null;
    history = []
    onQuestionarioConcluido = null;
    onVoltarTelaInicialClick = null;

    constructor (props) {
        super(props);
        this.questionario = this.props.questionario;
        this.session = this.props.session;
        this.onQuestionarioConcluido = this.props.onQuestionarioConcluido;
        this.onVoltarTelaInicialClick = this.props.onVoltarTelaInicialClick;
        this.state = {
            ...INITIAL_STATE,
            perguntaIndex: 0,
            pergunta: this.questionario.perguntas[0],
        }
    }

    getAxiosInstance = () => {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                "Authorization": "Bearer " + (localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser')).jwtToken),
            },
        });
    }

    handleAvancar = (resposta) => {
        this.history.push(this.state);
        this.setState({
            resposta: resposta,
        });

        const perguntaParaPersistir = this.questionario.perguntas.find(i => i.id == this.state.pergunta.id);
        if (perguntaParaPersistir) {
            perguntaParaPersistir.resposta = resposta;
        }

        if (!this.state.ultimaPergunta) {
            let offset = 1;
            if (this.state.pergunta.desvio) {
                const proximoId = this.state.pergunta.desvio[resposta];
                if (proximoId) {
                    const proximoIndex = this.questionario.perguntas.findIndex(i => i.id == proximoId);
                    if (proximoIndex > -1) {
                        offset = proximoIndex - this.state.perguntaIndex;
                    } else {
                        alert("Desvio de fluxo inválido. Id da pergunta destino não encontrado.");
                    }
                }
            }

            setTimeout(() =>
                this.setState({
                    perguntaIndex: this.state.perguntaIndex + offset,
                    pergunta: this.questionario.perguntas[this.state.perguntaIndex + offset],
                    resposta: null,
                    ultimaPergunta: this.state.perguntaIndex + offset + 1 >= this.questionario.perguntas.length,
                    perguntaAnteriorIndex: this.state.perguntaIndex
                }), 250);
        }
    }

    handleVoltar = () => {
        this.setState({...this.history.pop()});
    }

    handleFinalizar = () => {
        this.setState({
            loading: true
        });
        const resposta = {
            usuario: getSessionUser(),
            auditado: {
                id: this.session.funcionario ? this.session.funcionario.id : null,
                cpf: this.session.cpf,
                nome: this.session.name
            },
            localidade: {
                id: this.session.unidade.id,
                nome: this.session.unidade.nome
            },
            questionario: this.questionario
        }
        delete resposta.questionario.created_at;
        delete resposta.questionario.updated_at;

        this.getAxiosInstance().post("questionarios/responder", resposta)
            .then(res => {
                this.setState({
                    loading: false
                });
                Alert.success("Respostas armazenadas.", {
                    position: 'bottom-right',
                    effect: 'stackslide',
                });
                const alertasConclusao = this.getAlertasConclusao();
                this.onQuestionarioConcluido(alertasConclusao);
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                console.error("Erro ao gravar resposta do questionário");
                console.error(error.message);
                Alert.error("Erro ao armazenar respostas.", {
                    position: 'bottom-right',
                    effect: 'stackslide',
                });
            });
    }

    getAlertasConclusao() {
        if (this.questionario.conclusao == null) {
            return [];
        }
        let alertas = [];
        this.questionario.conclusao.forEach(item => {
            if (item.quandoUmForFalso) {
                const adicionar = this.questionario.perguntas.filter(i =>
                    i.tipo === "boolean" &&
                    i.resposta === false &&
                    item.quandoUmForFalso.find(x => i.id === x) !== undefined);
                if (adicionar.length > 0) {
                    alertas.push(item.alerta);
                }
            }
        });
        return alertas;
    }

    render() {
        return (
            this.state.loading
            ?
            <div style={{ display: "flex", justifyContent: "center", fontSize: "50px" }}>
                <Icon type={"loading"} />
            </div>
            :
            <Row>
                <Col className={"mb-30 text-center"} md={24}>
                    {this.questionario.titulo}
                </Col>
                <Col className={"mb-30 text-center"} md={24}>
                    <div className="mb-5">
                        <Text strong>{this.state.pergunta.pergunta}</Text>
                    </div>

                    {   this.state.pergunta.tipo === "boolean" &&
                        <RespostaBoolean
                            resposta={this.state.resposta}
                            onAvancarClick={(event) => this.handleAvancar(event.target.value)} />
                    }
                    {   this.state.pergunta.tipo === "string" &&
                        <RespostaString
                            resposta={this.state.resposta}
                            onAvancarClick={(text) => this.handleAvancar(text)} />
                    }
                </Col>

                {   this.state.ultimaPergunta &&
                    <Col xs={24}>
                        <Button
                            onClick={() => this.handleFinalizar()}
                            block
                            className="btn-brand mb-30"
                            disabled={this.state.resposta == null}
                            htmlType='button'>
                            Finalizar
                        </Button>
                    </Col>
                }

                <Col xs={24}>
                    <Button
                        onClick={() => this.state.perguntaIndex > 0
                            ? this.handleVoltar()
                            : this.onVoltarTelaInicialClick() }
                        block
                        className="btn-brand mb-30"
                        htmlType='button'>
                        Voltar
                    </Button>
                </Col>
            </Row>
        )
    }
}

export default Questionario;
