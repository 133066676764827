import React from 'react';
import { Col, Radio, Row } from 'antd'
import SubmitAdvance from './components/submitAdvance';
import SubmitOrFinishAdvance from './components/submitOrFinishAdvance';

const temperatureList = [
    {
        label: 'Abaixo de 37.5 °C',
        value: 'abaixo_de_37.5'
    },
    {
        label: 'Igual ou acima de 37.6 °C',
        value: 'igual_ou_acima_de_37.6'
    },
    {
        label: 'Acima de 39 °C',
        value: 'acima_de_39'
    },
    {
        label: 'Sem leitura',
        value: 'sem_leitura'
    },
];

const getUser = () => {
    return JSON.parse(localStorage.getItem('authUser'));
};

const Temperature = ({ step, setStep, isSubmit, formik, funcionario }) => (
    <Row>
        <Col className={"mb-30 text-center"} md={24}>
            Feito! Agora, informe a temperatura do colaborador(a): {funcionario.nome}
        </Col>
        {
            getUser() && getUser().cpf === "07072034900" &&
            <Col className={"mb-30 text-center"} md={24}>
                CPF: {funcionario.cpf}
            </Col>
        }
        <Col className={"mb-30 text-center"} md={24}>
            <Radio.Group size="large" name='temperature'>
                <Row gutter={30}>
                    {
                        temperatureList.map((elem, key) => {
                            return (
                                <Col xs={24} md={12} className={'mb-15'} key={key}>
                                    <Radio.Button
                                        onChange={formik.handleChange}
                                        value={elem.value}
                                        className={"custom-radio-button "}>
                                        {elem.label}
                                    </Radio.Button>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Radio.Group>
        </Col>
        {
            formik.values.temperature === "abaixo_de_37.5" 
            ? <SubmitOrFinishAdvance
                disabled={!formik.values.temperature}
                step={step}
                setStep={setStep}
                isSubmit={isSubmit} />
            : <SubmitAdvance
                disabled={!formik.values.temperature}
                step={step}
                setStep={setStep}
                isSubmit={isSubmit} /> 
        }
    </Row>
);

export default Temperature;