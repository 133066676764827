import React, { useState, useEffect } from 'react';
import { BrowserQRCodeReader } from '@zxing/library';
import { Row, Col, Modal, Input, Skeleton, Table } from 'antd'
import device from 'current-device';
import axios from 'axios';

const ReportedPerson = ({ isScanning, isInsertCpf, handleScan, handleCpfModal, handleCpf, debugMessage }) => {
    const [cpf, setCpf] = useState(null);
    const [nome, setNome] = useState(null);
    const [celular, setCelular] = useState(null);
    const [unidade, setUnidade] = useState(null);
    const [unidadeId, setUnidadeId] = useState(null);
    const [cpfInformado, setCpfInformado] = useState(false);
    const [carregandoDados, setCarregandoDados] = useState(false);
    const [novoCadastro, setNovoCadastro] = useState(false);
    const [funcionario, setFuncionario] = useState(null);
    const [funcionarios, setFuncionarios] = useState([]);

    useEffect(() => {
        if (isScanning) {
            startScanner();
        }
    }, [])

    function getAxiosInstance() {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                "Authorization": "Bearer " + (localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser')).jwtToken),
            },
        });
    }

    function carregarNome(value) {
        let cpf = value.replace(/[^0-9]/g,'');
        if (cpf.length > 11) {
            cpf = cpf.substring(0, 11);
            setCpf(cpf);
            return;
        }

        const cpfInformado = cpf && cpf.length === 11;
        setCpfInformado(cpfInformado);
        setFuncionario(null);
        if (cpfInformado) {
            setCarregandoDados(true);

            const getFuncHandle = response => {
                const func = response && response.data && response.data.length > 0 ? response.data[0] : null;
                setFuncionario(func);
                setNovoCadastro(func == null);
                if (func) {
                    setNome(func.nome);
                    setCelular(func.contato && func.contato.celular);
                    setUnidade(func.unidade);
                    setUnidadeId(func.unidadeId);
                } else {
                    setNome("");
                    setCelular("");
                    setUnidade("");
                    setUnidadeId(null);
                }
                setCarregandoDados(false);
            };

            getAxiosInstance().get(`funcionario/${cpf}`)
                .then(getFuncHandle)
                .catch(error => getFuncHandle(null));
        } else if (cpf && cpf.length > 2) {
            getAxiosInstance().get(`funcionario/${cpf}`)
                .then(res => {
                    setFuncionarios(res.data)
                });
        } else {
            setFuncionarios([]);
        }
    }

    function validate(phone) {
        if (phone === null) {
            return false;
        }
        const number = phone.replace(/[^0-9]/gi, '');
        return number.length >= 10;
    };

    function startScanner() {
        const codeReader = new BrowserQRCodeReader();

        codeReader
            .listVideoInputDevices()
            .then(videoInputDevices => {
                videoInputDevices.forEach(device => console.log(`${device.label}, ${device.deviceId}`));

                const firstDeviceId = videoInputDevices[videoInputDevices.length - 1].deviceId;

                codeReader
                    .decodeOnceFromVideoDevice(firstDeviceId, 'video')
                    .then(result => {
                        console.log(result.text);
                        handleScan(result.text);
                        codeReader.stopStreams();
                    });
            })
            .catch(err => console.error(err));
    }

    return (
        <Row>
            {
                isScanning && <div>
                    <video
                        id="video"
                        width="100%"
                        style={{border: "1px solid gray"}}
                    ></video>
                    <p>{device.ios() ? "* Para uma melhor experiência utilize o Safari" : " * Para uma melhor experiência utilize o Google Chrome"}</p>
                    {debugMessage && debugMessage.trim() !== "" && <p>{debugMessage}</p>}
                </div>
            }
            {
                isInsertCpf &&
                <Modal
                    title="Insira o CPF colaborador"
                    visible={true}
                    maskClosable={false}
                    onOk={() => handleCpf(funcionario, cpf, nome, celular, unidade, unidadeId)}
                    onCancel={() => handleCpfModal(false)}
                    okButtonProps={{
                        className: "btn-brand",
                        disabled: (!cpfInformado || carregandoDados) || nome == ""
                    }}
                    cancelButtonProps={{
                        className: "btn-neutral"
                    }}
                    style={{ top: 10 }}
                    cancelText="Cancelar"
                    okText="Confirmar"
                >
                    <Row>
                        <Col className="mb-10">
                            <Input
                                autoFocus
                                placeholder="CPF"
                                type="number"
                                min="0"
                                inputmode="numeric"
                                pattern="[0-9]*"
                                title="CPF"
                                size="large"
                                value={cpf}
                                onChange={e => { setCpf(e.target.value); carregarNome(e.target.value); }} />
                            {(cpf && cpf.length > 11) &&
                            <label className="info-label">Informe apenas 11 dígitos no CPF</label>
                            }
                        </Col>
                        {/* <Col className="mb-10">
                            <MaskedInput
                                autoFocus
                                placeholder='CPF'
                                mask="111.111.111-11"
                                size="large"
                                value={cpf}
                                onChange={e => {carregarNome(e.target.value); setCpf(e.target.value);}}
                            />
                        </Col> */}
                    </Row>

                    { !cpfInformado &&
                    <Table
                        showHeader={false}
                        pagination={{ position: [] }}
                        dataSource={funcionarios}
                        locale={{
                            emptyText: "Informe 3 digitos para buscar funcionários"
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    setCpf(record.cpf);
                                    carregarNome(record.cpf);
                                }
                            }
                        }}
                        columns={[
                            {
                                dataIndex: 'cpf',
                            },
                            {
                                dataIndex: 'nome',
                            }]}
                            >
                    </Table>
                    }


                    { cpfInformado && carregandoDados && <Skeleton active />}
                    { cpfInformado && !carregandoDados &&
                    <div>
                        <Row>
                            <Col className="mb-10">
                                <Input
                                    placeholder="Nome do(a) colaborador(a)"
                                    size="large"
                                    onChange={e => setNome(e.target.value)}
                                    value={nome}
                                />
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col className="mb-10">
                                <MaskedInput
                                    placeholder="Celular"
                                    size="large"
                                    mask="(11) 11111-1111"
                                    onChange={e => setCelular(e.target.value.replace(/[^0-9]/g,''))}
                                    value={celular}
                                />
                            </Col>
                        </Row> */}

                        <Row>
                            <Col className="mb-10">
                                {!novoCadastro && <Input placeholder="Unidade" disabled size="large" value={unidade} />}
                            </Col>
                        </Row>
                    </div>
                    }
                </Modal>
            }
        </Row>
    )
};

export default ReportedPerson;