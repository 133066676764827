import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Icon, Button } from 'antd'
import axios from 'axios';

const Result = ({ data, feedback, questionarioRespondido, onRestartClick, onNovoDiagnosticoClick, onResponderQuestionarioClick }) => {
    const [questionarios, setQuestionarios] = useState(null);

    useEffect(() => {
        checkquestionarios();
    }, [])

    function getAxiosInstance() {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                "Authorization": "Bearer " + (localStorage.getItem('authUser') && JSON.parse(localStorage.getItem('authUser')).jwtToken),
            },
        });
    }

    function checkquestionarios() {
        getAxiosInstance().get(`medicalUnit/${data.unidade.id}?expandirQuestionarios=true`)
                .then(res => {
                    setQuestionarios(res.data.questionarios);
                })
                .catch(error => setQuestionarios(null));
    }

    return (
        feedback ?
            <div>
                { !questionarioRespondido && questionarios && questionarios.length > 0 &&
                    <Row>
                        <Col className={'mb-30'}>
                            <p>
                                <Button
                                    block
                                    onClick={() => onResponderQuestionarioClick(questionarios[0])}
                                    size={"large"}
                                    className="btn-attention mt-25"
                                    icon="scan">
                                    {questionarios[0].titulo}
                                </Button>
                            </p>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col className={'mb-30'}>
                        <p>
                            <Button
                                block
                                onClick={() => onNovoDiagnosticoClick(true)}
                                size={"large"}
                                className="btn-brand mt-25"
                                icon="scan">
                                Scanear crachá
                            </Button>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className={'mb-30'}>
                        <p>
                            <Button
                                block
                                onClick={() => onNovoDiagnosticoClick(false)}
                                size={"large"}
                                className="btn-brand mt-25"
                                icon="user">
                                Novo Diagnóstico
                            </Button>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className={'mb-30'}>
                        <p>
                            <Button
                                block
                                onClick={() => onRestartClick()}
                                size={"large"}
                                className="btn-brand mt-25"
                                icon="home">
                                Voltar ao início
                            </Button>
                        </p>
                    </Col>
                </Row>
            </div>
            :
            <div style={{ display: "flex", justifyContent: "center", fontSize: "50px" }}>
                <Icon type={"loading"} />
            </div>
    );
}

export default Result;