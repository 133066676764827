import React from 'react';
import { Row, Col, Typography, Radio } from 'antd'

const booleanValues = [
    {
        label: 'Sim',
        value: true
    },
    {
        label: 'Não',
        value: false
    }
];

const RespostaBoolean = ({resposta, onAvancarClick}) => {
    return (
        <Radio.Group size="large" className="mb-5" value={resposta}>
            <Row gutter={30}>
                {
                    booleanValues.map((elem, i) => (
                        <Col xs={12} key={i}>
                            <Radio.Button
                                onChange={(event) => onAvancarClick(event)}
                                value={elem.value}
                                className={"custom-radio-button little-button"}>
                                {elem.label}
                            </Radio.Button>
                        </Col>
                    ))
                }
            </Row>
        </Radio.Group>
    )
}

export default RespostaBoolean;